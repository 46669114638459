var offset = 70;
$('.jsScrollTo').on('click',function(e) {
	e.preventDefault();
	var target = this.hash;
	if ($(this).data('offset') != undefined) offset = $(this).data('offset');
	$('html, body').stop().animate({
		'scrollTop': $(target).offset().top - offset
	}, 500, 'swing', function() {
		// window.location.hash = target;
	});
});

$(function(){
    $('.truncate').succinct({
        size: 200
    });
});

$(".st-word").each(function() {
  // Some Vars
  var elText,
      openSpan = '<span class="first-word">',
      closeSpan = '</span>';
  
  // Make the text into array
  elText = $(this).text().split(" ");
  
  // Adding the open span to the beginning of the array
  elText.unshift(openSpan);
  
  // Adding span closing after the first word in each sentence
  elText.splice(2, 0, closeSpan);
  
  // Make the array into string 
  elText = elText.join(" ");
  
  // Change the html of each element to style it
  $(this).html(elText);
});