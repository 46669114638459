		/* MINIGALLERY */
		
		$(function() {
			var _direction = 'left';
			$('#carousel').carouFredSel({
				direction: _direction,
				responsive: true,
				circular: true,
				items: {
					width: 130,
					height: '180',
					visible: {
						min: 2,
						max: 10
					}
				},
				scroll: {
					items: 1,
					duration: 1000,
					timeoutDuration: 1500,
					pauseOnHover: 'immediate',
					onEnd: function( data ) {
						_direction = ( _direction == 'left' ) ? 'right' : 'left';
						$(this).trigger( 'configuration', [ 'direction', _direction ] );
					}
				}
			});
		});
		
		/*end*/

$(function() {

	$(' #da-thumbs > li ').each( function() { $(this).hoverdir({
		hoverDelay : 75
	}); } );

});

function add_js_input(form_id){
    $('<input>').attr({
                type: 'hidden',
                id: 'captcha',
                name: 'captcha',
                value: 'honey'}).appendTo(form_id);
}
$(document).ready(function(){

    //materialDocumentReady();
    //if($("#contactUsMap").length) {
    //    materialKitDemo.initContactUsMap();
    //}
    $('.parallax-window').parallax();
    
    $('body').fitVids();
    
    // isotope 
		var $grid = $('.grid').isotope({
			itemSelector: '.grid-item',
			layoutMode: 'masonry',
			
		});
			// filter items on button click
			$('.filter-button-group').on( 'click', 'a', function() {
			var filterValue = $(this).attr('data-filter');
			$grid.isotope({ filter: filterValue });
		});

		$('#filters a').click(function() {
			$('#filters .current').removeClass('current');
			$(this).addClass('current');
			var selector = $(this).attr('data-filter');		
		});

	// end isotope 

});
$(window).on("load", function (e) {
    $("table").addClass('table table-bordered table-hover');
    
    if(window.validate_contact_form){
		validate_contact_form();
		add_js_input('#contact_form');
	}

})
//$(window).load(function() {

//});
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});